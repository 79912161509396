.c-properties-card {
	margin-bottom: $defaultVerticalMargin
}
.c-properties-card__logo-wrapper {
	margin-bottom: 10px;
}
.c-properties-card__items {
	margin-bottom: $defaultVerticalMargin/2;
	padding: 0;
}
.c-properties-card__item {
	padding: 5px 0;
	border-bottom: 1px solid $highlight--darker;
	margin: 0;
	@include clearfix();
}
.c-properties-card__item--is-last,
.c-properties-card__item:last-child {
	border-bottom: 0;
}

.c-properties-card__key {
	margin-bottom: 5px;
}

.c-properties-card__items--inline .c-properties-card__key,
.c-properties-card__items--inline .c-properties-card__value {
	float: left;
}
.c-properties-card__items--inline .c-properties-card__key {
	width: 40%;
	padding-right: 10px;
}
.c-properties-card__items--inline .c-properties-card__value {
	width: 60%;
	padding: 0;
}

@include mq($until: lap) {
	.c-properties-card--to-card-layout {
		padding: 15px;
		border: 1px solid $highlight--darker;
		border-radius: 3px;
	}
}

/**/

.c-properties-list {
	margin: 0;
	padding: 0;
}
.c-properties-list__item {
	border-bottom: 1px solid $highlight--darker;
	margin: 0;
	padding: 5px 0;
	@include clearfix();
}
.c-properties-list__item--is-last,
.c-properties-list__item:last-child {
	border-bottom: 0;
}
.c-properties-list__key,
.c-properties-list__value {
	float: left;
}
.c-properties-list__key {
	width: 25%;
	padding-right: 20px;
}
.c-properties-list__value {
	width: 75%;
}

@include mq($until: palm) {
	.c-properties-list__key,
	.c-properties-list__value {
		font-size: 0.9em;
	}

	.c-properties-list__key {
		width: 35%;
	}
	.c-properties-list__value {
		width: 65%;
	}
}

@include mq($until: 450px) {
	.c-properties-list__key {
		width: 100%;
	}
	.c-properties-list__value {
		width: 100%;
	}
}

.c-properties-list--half .c-properties-list__key,
.c-properties-list--half .c-properties-list__value {
	width: 50%;
}