/**
 * Toolkit CSS     
 */
/* Variables */
/* Mixins */
/* Layout */
/* Components */
.c-basket-credits {
  position: relative; }
  .c-basket-credits {
    zoom: 1 !important; }
  .c-basket-credits:before, .c-basket-credits:after {
    content: " " !important;
    display: table !important; }
  .c-basket-credits:after {
    clear: both !important; }
  @media (max-width: 53.115em) {
    .c-basket-credits {
      border-bottom: 1px solid #f6f6f6;
      padding-bottom: 5px; } }

.c-basket-credits__inner {
  float: right; }

.js .c-basket-credits__inner {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: -80px; }
  @media (max-width: 53.115em) {
    .js .c-basket-credits__inner {
      position: static;
      float: right; } }

.c-basket-credits__item {
  float: left;
  margin-left: 20px; }

.c-credits-dropdown-label {
  padding: 0 0 5px 10px; }
  .js .c-credits-dropdown-label {
    cursor: pointer; }

.c-credits-dropdown__item {
  display: block;
  padding: 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee; }
  .c-credits-dropdown__item--is-last {
    margin-bottom: 0;
    border-bottom: 0; }

.c-facet-links__item {
  margin-bottom: 0px; }
  .c-facet-links__item--is-active {
    margin-bottom: 6px; }

.c-facet-links__link {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 3px;
  width: 100%;
  padding: 3px 10px; }
  .c-facet-links__link {
    zoom: 1 !important; }
  .c-facet-links__link:before, .c-facet-links__link:after {
    content: " " !important;
    display: table !important; }
  .c-facet-links__link:after {
    clear: both !important; }

.c-facet-links__item--is-active .c-facet-links__link {
  border-color: #ddd; }

.c-facet-links__count {
  color: #333;
  padding-left: 5px; }

.c-facet-links__action {
  display: inline-block;
  float: right; }

.c-facet-links__item--more,
.c-facet-links__item--less {
  display: none; }
  .js .c-facet-links__item--more, .js
  .c-facet-links__item--less {
    display: block; }

.js .c-facet-links__more-links {
  display: none; }

.c-brick {
  margin-bottom: 30px; }

.c-brick,
.c-brick__inner {
  position: relative; }

/* If .c-brick is contained in a grid with flexbox applied, apply display: flex */
.l-grid--flex.l-grid--stretch .c-brick {
  display: flex;
  display: -ms-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .l-grid--flex.l-grid--stretch .c-brick > * {
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }

.c-brick__link {
  display: block;
  color: #333; }
  .c-brick__link {
    zoom: 1 !important; }
  .c-brick__link:before, .c-brick__link:after {
    content: " " !important;
    display: table !important; }
  .c-brick__link:after {
    clear: both !important; }
  .c-brick__link:hover {
    color: #333;
    background: #f6f6f6; }

.c-brick__header {
  margin-bottom: 14px; }

.c-brick__header--shortlist-star {
  padding-right: 60px; }

.c-brick__logo-wrapper {
  width: 100%;
  float: none;
  margin-bottom: 15px; }

.c-brick__logo {
  border: 1px solid #eee;
  display: block; }

.c-brick__title {
  margin-bottom: 10px; }

.c-brick__shortlist {
  position: absolute;
  top: 15px;
  right: 15px; }

/*
 * Testimonial
 */
.c-speech-bubble {
  padding: 15px;
  border: 1px solid #ddd;
  position: relative;
  background: #fff; }

.c-speech-bubble:before, .c-speech-bubble:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 1.5em solid #fff;
  border-right: 1.5em solid transparent; }

.c-speech-bubble:before {
  left: 20px;
  bottom: -22px;
  z-index: 2; }

.c-speech-bubble:after {
  left: 19px;
  bottom: -24px;
  border-top-color: #ddd;
  z-index: 1; }

.c-speech-bubble__author {
  margin-bottom: 0; }

/*
 * CTAs
 */
.c-cta__icon {
  float: left; }

.c-cta__icon i:after {
  font-size: 55px; }

.c-cta__icon img {
  width: 55px; }

.c-cta__content {
  padding-left: 75px; }

.c-cta__header {
  margin-bottom: 5px; }

/*
 * Featured Event
 */
.c-brick--featured-event .c-brick__content {
  position: relative;
  padding-left: 95px;
  min-height: 70px; }

.c-brick--featured-event .c-brick__event-date-calendar {
  position: absolute;
  left: 0;
  top: 2px; }

.c-event-date-calendar {
  position: relative;
  border-radius: 3px;
  background: #fff;
  text-align: center;
  width: 75px;
  border: 1px solid #eee; }

.c-event-date-calendar__month {
  background: #333;
  padding: 3px 0;
  font-size: 1em;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.c-event-date-calendar__day {
  padding: 4px 0;
  font-size: 1.5em; }

.c-event-date-calendar__month:after,
.c-event-date-calendar__day:after {
  display: block;
  speak: none; }

.c-event-date-calendar__month:after {
  content: attr(data-event-date-month);
  color: #fff;
  text-transform: uppercase; }

.c-event-date-calendar__day:after {
  content: attr(data-event-date-day); }

/* When screen gets too small for calendar to be next the text, put calendar on its own line and centered */
@media (max-width: 25em) {
  .c-brick--featured-event .c-brick__content {
    padding-left: 0; }
  .c-brick--featured-event .c-brick__event-date-calendar {
    position: static; }
  .c-event-date-calendar {
    margin: 0 auto 15px; } }

/* Multi-items brick */
.c-brick__item {
  clear: both;
  margin-bottom: 15px; }

.c-brick--multi-items .c-brick__link:hover {
  background: none; }

/*
 * We can have the logo and the content next to each other depending on the size of the parent grid item.
 * 
 * On Career Center, the brick " Featured jobs" can contain up to 4 jobs and the size of the brick itself is setup
 * in the CMS (it can be full width, two thirds, ...) which means that the logo can't always be next to the content
 * if there's not enough space in the container.
 * 
 * If the parent grid item is full width (one-whole -- the jobs would then be on top of each other), it means that
 * there is enough space for the logo to be next to the content.
 * Using another width (for example one-third) would put the jobs next to each other. In this case, we want to keep
 * the logo on top of the content as there won't be enough space for them to be next to each other.
 * 
 * We have to use media queries to get the right layout for each breakpoint (since we are using the breakpoint dependent
 * grid classes)
 *
 * Same thing for articles
*/
/* Desktop size => @media screen and (min-width: 1201px) */
@media (min-width: 75em) {
  .l-grid__item.one-whole > .c-brick__item .c-brick__logo-wrapper,
  .c-brick--featured-jobs .l-grid__item.one-half > .c-brick__item .c-brick__logo-wrapper {
    float: left;
    width: 45%;
    padding-right: 20px; }
  .l-grid__item.one-whole > .c-brick__item .c-brick__logo-wrapper + .c-brick__content,
  .c-brick--featured-jobs .l-grid__item.one-half > .c-brick__item .c-brick__logo-wrapper + .c-brick__content {
    float: left;
    width: 55%; } }

/* Laptop size => @media screen and (min-width: 851px) and (max-width: 1200px) */
@media (min-width: 53.125em) and (max-width: 74.99em) {
  .l-grid__item.lap--one-whole > .c-brick__item .c-brick__logo-wrapper,
  .c-brick--featured-jobs .l-grid__item.lap--one-half > .c-brick__item .c-brick__logo-wrapper {
    float: left;
    width: 45%;
    padding-right: 20px; }
  .l-grid__item.lap--one-whole > .c-brick__item .c-brick__logo-wrapper + .c-brick__content,
  .c-brick--featured-jobs .l-grid__item.lap--one-half > .c-brick__item .c-brick__logo-wrapper + .c-brick__content {
    float: left;
    width: 55%; } }

/* Laptop size => @media screen and (min-width: 551px) and (max-width: 850px) */
@media (min-width: 34.375em) and (max-width: 53.115em) {
  .l-grid__item.portable--one-whole > .c-brick__item .c-brick__logo-wrapper {
    float: left;
    width: 45%;
    padding-right: 20px; }
  .l-grid__item.portable--one-whole > .c-brick__item .c-brick__logo-wrapper + .c-brick__content {
    float: left;
    width: 55%; } }

/*
 * Products
 */
.c-brick--products .c-brick__item {
  padding: 35px 20px;
  border: 1px solid #ddd;
  border-radius: 3px; }

.c-brick--products .c-brick__content {
  max-width: 400px;
  margin: 0 auto; }

.c-brick--products .c-brick__content li {
  margin-bottom: 10px; }

.c-brick--products .c-brick__item--product-with-bundles {
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column wrap;
      flex-flow: column wrap; }

.c-brick__item--product-with-bundles .c-brick__main-product {
  padding: 35px 20px 25px;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }

.c-brick__item--product-with-bundles .c-brick__related-bundles {
  width: 100%;
  -ms-flex-item-align: end;
      align-self: end; }

.c-brick--products .c-related-bundles {
  margin-bottom: 0; }

.c-brick--products .c-related-bundles__item {
  padding: 10px 15px 12px;
  border-top: 1px solid #ddd;
  text-align: left; }

.c-product-selector {
  margin-bottom: 20px; }

.js .c-product-selector__radio {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.c-product-selector__label {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 15px;
  padding-bottom: 17px;
  position: relative;
  cursor: pointer; }

.c-product-selector__name,
.c-product-selector__desc {
  margin-bottom: 10px; }

.c-product-selector__name {
  padding-right: 45px; }

.js .c-product-selector__label:before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ddd;
  background: #fff;
  position: absolute;
  top: 15px;
  right: 15px;
  box-sizing: border-box;
  font-family: 'toolkit-core-icons';
  color: inherit;
  speak: none;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 6px;
  text-align: center; }

/* When radio button is checked, change label visual */
.c-product-selector__radio:checked + .c-product-selector__label {
  border-color: #d0d0d0;
  background: #eee; }

.js .c-product-selector__radio:checked + .c-product-selector__label:before {
  content: "\EA0B"; }

/* Disabled version */
.c-product-selector__radio:disabled + .c-product-selector__label {
  border-color: #ddd !important;
  background: #eee !important; }

.c-product-selector__radio:checked:disabled + .c-product-selector__label {
  border-color: #666 !important; }

.c-product-selector__radio:disabled + .c-product-selector__label:before {
  border-color: #ddd !important;
  color: #666 !important; }

.c-product-selector__radio:disabled + .c-product-selector__label .c-product-selector__name {
  color: #666 !important; }

.c-attach-supporting-doc {
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 3px;
  position: relative;
  transition: opacity 0.5s; }

.c-attach-supporting-doc--existing-doc {
  padding-top: 30px; }

.c-attach-supporting-doc--remove-selected {
  opacity: 0.5; }

.c-attach-supporting-doc__action-remove {
  position: absolute;
  top: 7px;
  right: 15px; }

.js .c-remove-supporting-doc__checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.js .c-remove-supporting-doc__label {
  padding-right: 25px;
  font-size: 0.9em; }

.js .c-remove-supporting-doc__label:after {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  background: #fff;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 2px;
  font-family: 'mdgx-icons';
  color: inherit;
  speak: none;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 3px;
  text-align: center;
  font-size: 11px; }

.js .c-remove-supporting-doc__checkbox:checked + .c-remove-supporting-doc__label:after {
  content: "\EA34"; }

.c-job-description {
  margin-bottom: 30px; }

.c-job-description__header {
  position: relative;
  padding-right: 70px; }

.c-job-description__shortlist {
  position: absolute;
  top: 5px;
  right: 0; }

/* Static bar appears at portable breakpoint */
@media (max-width: 53.115em) {
  .c-job-description__header {
    padding-right: 0; }
  .c-job-description__shortlist {
    display: none; } }

.c-lister,
.c-mini-lister {
  margin: 0;
  padding: 0; }

.c-lister__item {
  margin-bottom: 20px;
  position: relative; }
  .c-lister__item:last-child {
    margin-bottom: 0; }

.c-lister__link {
  display: block;
  color: #333; }
  .c-lister__link:hover {
    background: #f6f6f6;
    color: #333; }

.c-lister__shortlist {
  position: absolute;
  top: 7px;
  right: 10px; }
  @media (max-width: 34.365em) {
    .c-lister__shortlist {
      top: auto;
      bottom: 10px; } }

.c-job-stub,
.c-recruiter-stub,
.c-cv-stub {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px; }

@media (max-width: 34.365em) {
  .c-job-stub {
    padding-bottom: 25px; } }

.c-recruiter-stub__header-wrapper,
.c-article-stub__header-wrapper,
.c-cv-stub__header-wrapper {
  margin-bottom: 10px; }

.c-job-stub__header-wrapper {
  margin-bottom: 2px; }

.c-cv-stub__header-wrapper {
  padding-right: 50px; }

.c-job-stub__header,
.c-recruiter-stub__header,
.c-article-stub__header,
.c-cv-stub__header {
  margin-bottom: 0;
  font-weight: bold; }

.c-job-stub__header,
.c-job-stub__new-flag {
  display: inline-block; }

.c-job-stub__new-flag {
  color: #317F0F;
  text-transform: uppercase;
  padding-left: 5px;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 0;
  cursor: help; }

.c-recruiter-stub__jobcount {
  display: inline-block;
  border-left: 1px solid #ddd;
  margin-left: 7px;
  padding-left: 7px;
  font-size: 0.85em; }

.c-job-stub__inner,
.c-recruiter-stub__inner,
.c-article-stub__inner,
.c-cv-stub__inner {
  position: relative; }

.c-job-stub__details,
.c-recruiter-stub__details {
  margin-bottom: 10px; }

.c-job-stub__logo-wrapper,
.c-recruiter-stub__logo-wrapper {
  text-align: right; }

.c-recruiter-stub__logo-wrapper {
  padding-top: 4px; }

.c-job-stub__logo,
.c-recruiter-stub__logo {
  border: 1px solid #ddd; }

.c-job-stub__recruiter {
  margin-bottom: 10px; }

.c-job-stub__description {
  margin-bottom: 0px; }

.c-cv-stub__lock {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 130%; }

.c-article-stub {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px; }
  .c-lister__item:last-child .c-article-stub {
    border-bottom: 0;
    padding-bottom: 0; }

.c-mini-lister-stub {
  border-bottom: 1px solid #ddd;
  padding: 5px 0px;
  position: relative; }
  .c-mini-lister__item:last-child .c-mini-lister-stub {
    border-bottom: 0; }

.c-mini-lister-stub__inner {
  padding-right: 20px; }

.c-mini-lister-stub__header {
  font-size: 1em;
  margin-bottom: 0; }

.c-mini-lister-stub__properties {
  margin-bottom: 0;
  color: #333; }

.c-mini-lister-stub__spinner {
  position: absolute;
  top: calc(50% - 9px);
  right: 5px; }

.c-logo {
  border: 1px solid #eee; }

.c-properties-card {
  margin-bottom: 30px; }

.c-properties-card__logo-wrapper {
  margin-bottom: 10px; }

.c-properties-card__items {
  margin-bottom: 15px;
  padding: 0; }

.c-properties-card__item {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  margin: 0; }
  .c-properties-card__item {
    zoom: 1 !important; }
  .c-properties-card__item:before, .c-properties-card__item:after {
    content: " " !important;
    display: table !important; }
  .c-properties-card__item:after {
    clear: both !important; }

.c-properties-card__item--is-last,
.c-properties-card__item:last-child {
  border-bottom: 0; }

.c-properties-card__key {
  margin-bottom: 5px; }

.c-properties-card__items--inline .c-properties-card__key,
.c-properties-card__items--inline .c-properties-card__value {
  float: left; }

.c-properties-card__items--inline .c-properties-card__key {
  width: 40%;
  padding-right: 10px; }

.c-properties-card__items--inline .c-properties-card__value {
  width: 60%;
  padding: 0; }

@media (max-width: 53.115em) {
  .c-properties-card--to-card-layout {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 3px; } }

/**/
.c-properties-list {
  margin: 0;
  padding: 0; }

.c-properties-list__item {
  border-bottom: 1px solid #ddd;
  margin: 0;
  padding: 5px 0; }
  .c-properties-list__item {
    zoom: 1 !important; }
  .c-properties-list__item:before, .c-properties-list__item:after {
    content: " " !important;
    display: table !important; }
  .c-properties-list__item:after {
    clear: both !important; }

.c-properties-list__item--is-last,
.c-properties-list__item:last-child {
  border-bottom: 0; }

.c-properties-list__key,
.c-properties-list__value {
  float: left; }

.c-properties-list__key {
  width: 25%;
  padding-right: 20px; }

.c-properties-list__value {
  width: 75%; }

@media (max-width: 34.365em) {
  .c-properties-list__key,
  .c-properties-list__value {
    font-size: 0.9em; }
  .c-properties-list__key {
    width: 35%; }
  .c-properties-list__value {
    width: 65%; } }

@media (max-width: 28.125em) {
  .c-properties-list__key {
    width: 100%; }
  .c-properties-list__value {
    width: 100%; } }

.c-properties-list--half .c-properties-list__key,
.c-properties-list--half .c-properties-list__value {
  width: 50%; }

.c-shortlist-button {
  padding: 0;
  margin: 0;
  background: none;
  line-height: 1;
  border: 0;
  outline: 0; }

.c-shortlist-button__text,
.c-icon-label {
  font-size: 0.9em; }

.c-sortby {
  margin-bottom: 15px; }

.c-sortby__label {
  margin-right: 10px; }

.c-sortby__input {
  display: inline-block;
  width: auto; }

.c-sortby__list {
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 0; }
  .c-sortby__list li {
    margin-bottom: 0; }

.c-static-bar {
  display: block;
  width: 100%;
  padding: 7px 0;
  background: #eee;
  border-top: 1px solid #ddd;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 5; }

.c-static-bar__inner {
  max-width: 850px;
  margin: 0 auto; }

.c-your-jobs-card {
  margin-bottom: 15px;
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 3px; }
  .c-your-jobs-card {
    zoom: 1 !important; }
  .c-your-jobs-card:before, .c-your-jobs-card:after {
    content: " " !important;
    display: table !important; }
  .c-your-jobs-card:after {
    clear: both !important; }

.c-your-jobs-card__items,
.c-your-jobs-card ul {
  margin-bottom: 0; }

.c-your-jobs-card__item {
  display: block;
  border-bottom: 1px dotted #ddd;
  padding: 10px 0; }
  .c-your-jobs-card__item:last-child {
    border-bottom: 0; }

.c-your-jobs-card__item--actions {
  font-size: 0.9em;
  padding-top: 25px; }

@media (max-width: 34.365em) {
  .c-your-jobs-card__item--actions .l-grid__item:first-child {
    margin-bottom: 15px; } }

.c-dashboard-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  text-align: left;
  table-layout: fixed;
  background: #fff; }

.c-dashboard-table td {
  border: 1px solid #ddd;
  padding: 15px 15px 10px; }

.c-dashboard-table__number {
  display: block;
  font-size: 2.8em; }

.c-dashboard-table__number--live {
  color: #00A14B; }

.c-dashboard-table__number--expiring {
  color: #ED1C24; }

.c-dashboard-table__number--draft {
  color: #939598; }

@media (max-width: 34.365em) {
  .c-dashboard-table td {
    display: block;
    width: 100%;
    border-bottom: 0; }
    .c-dashboard-table td:last-child {
      border-bottom: 1px solid #ddd; } }

.c-payment-cards-list {
  display: block; }
  .c-payment-cards-list {
    zoom: 1 !important; }
  .c-payment-cards-list:before, .c-payment-cards-list:after {
    content: " " !important;
    display: table !important; }
  .c-payment-cards-list:after {
    clear: both !important; }

.c-payment-cards-list__item {
  display: block;
  float: left;
  margin-right: 5px;
  width: 40px; }

/* Javascript */
/* Autocomplete - Coming from devbridgeautocomplete */
.autocomplete-suggestions {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  border: 1px solid #ddd;
  overflow: auto; }

.autocomplete-suggestion {
  cursor: default;
  text-align: left;
  overflow: auto;
  padding: 3px 5px; }

.autocomplete-suggestions--dividers .autocomplete-suggestion {
  border-bottom: 1px solid #ddd;
  padding: 10px; }

.autocomplete-suggestion:last-child {
  border: none; }

.autocomplete-selected {
  background: #eee; }

.autocomplete-suggestion strong {
  color: #333; }

.autocomplete-no-suggestion {
  cursor: default;
  text-align: left;
  overflow: auto;
  padding: 3px 5px;
  opacity: 0.8; }

/* Structures */
.s-filter__subheader {
  margin-bottom: 5px; }

.s-filter__category {
  margin-bottom: 10px; }

.s-lister-jbe {
  margin-bottom: 30px; }
  .js .s-lister-jbe {
    text-align: right; }

.js .s-lister-jbe--aligned-with-tabs {
  margin-bottom: -33px; }
  @media (max-width: 74.99em) {
    .js .s-lister-jbe--aligned-with-tabs {
      margin-bottom: 30px; } }

.s-lister-jbe__button {
  width: auto; }
  @media (max-width: 34.365em) {
    .s-lister-jbe__button {
      width: 100%; } }
  .no-js .s-lister-jbe__button {
    margin-bottom: 15px; }

.s-lister-jbe__form {
  min-width: 500px;
  max-width: 500px; }
  @media (max-width: 53.115em) {
    .s-lister-jbe__form {
      min-width: 0; } }

.s-lister-jbe__field {
  margin-bottom: 0; }
  @media (max-width: 53.115em) {
    .s-lister-jbe__field {
      margin-bottom: 15px; } }

.s-share-with-exposed-links {
  border-top: 1px solid #ddd;
  padding-top: 15px;
  margin-bottom: 30px; }

.s-share-with-exposed-links__label {
  margin-bottom: 10px; }

.s-share-with-exposed-links .c-social-buttons {
  margin-bottom: 0; }

.s-share-with-exposed-links .c-social-buttons__item {
  display: block;
  margin-right: 0; }

.s-share-with-exposed-links .c-social-buttons__link {
  padding: 7px 0; }

.s-share-with-exposed-links .c-social-buttons__item:last-child .c-social-buttons__link {
  padding-bottom: 0; }

.s-share-with-exposed-links .c-social-buttons__link.i:before {
  font-size: 110%;
  width: 25px; }

.s-share-with-exposed-links .c-social-buttons__name {
  font-size: 0.9em; }

/* Print */
/*
This will be moved out of the toolkit.  
It's here for now so we can add selectors
that we want to brand and see the results in the pattern library.
*/
a,
.h-link,
.b-link-colour {
  color: #2a6ebb; }

a:hover,
.h-link:hover,
.b-link-colour:hover {
  color: #173d68; }

.b-button {
  background: #2a6ebb; }
  .b-button:hover {
    background: #215691; }

.b-button--main-action {
  background: #E75B0A; }
  .b-button--main-action:hover {
    background: #b64808; }

.b-highlight {
  background: #f2f7fc; }

.c-primary-nav__item--is-active .c-primary-nav__link,
.c-primary-nav-o__item--is-active .c-primary-nav-o__link {
  color: #173d68; }

/* Event date (little calendar thing) */
.c-event-date-calendar__month {
  background: #E75B0A; }

/* Highlighted job on lister */
.c-lister__link:hover .b-highlight {
  background: #e6eff9; }

/* Product selector styling */
.c-product-selector__label:before,
.c-product-selector__name {
  color: #2a6ebb; }

.c-product-selector__radio:checked + .c-product-selector__label {
  border-color: #2a6ebb;
  background: #f2f7fc; }
  .c-product-selector__radio:checked + .c-product-selector__label:before {
    border-color: #2a6ebb;
    color: #2a6ebb; }

/* Unlocked CV */
.c-cv-stub--is-unlocked {
  background: #f2f7fc; }

@media (max-width: 53.115em) {
  .js .c-top-bar {
    background: #2a6ebb; } }
