.c-dashboard-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    text-align: left;
    table-layout: fixed;
    background: #fff;
}

.c-dashboard-table td {
    border: 1px solid $highlight--darker;
    padding: 15px 15px 10px;
}

.c-dashboard-table__number {
    display: block;
    font-size: 2.8em;
}

.c-dashboard-table__number--live {
    color: #00A14B;
}
.c-dashboard-table__number--expiring {
    color: #ED1C24;
}
.c-dashboard-table__number--draft {
    color: #939598;
}

@include mq($until: palm) {
    .c-dashboard-table td {
        display: block;
        width: 100%;
        border-bottom: 0;

        &:last-child {
            border-bottom: 1px solid $highlight--darker;
        }
    }
}