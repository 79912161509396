.c-product-selector {
	margin-bottom: 20px;
}

.js .c-product-selector__radio {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.c-product-selector__label {
	display: block;
	width: 100%;
	border: 1px solid $highlight--darker;
	border-radius: 3px;
	padding: 15px;
	padding-bottom: 17px;
	position: relative;
	cursor: pointer;
}

.c-product-selector__name,
.c-product-selector__desc {
	margin-bottom: 10px;
}

.c-product-selector__name {
	padding-right: 45px;
}

.js .c-product-selector__label:before {
	content: '';
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 1px solid $highlight--darker;
	background: #fff;
	position: absolute;
	top: 15px;
	right: 15px;
	box-sizing: border-box;

	font-family: 'toolkit-core-icons';
	color: inherit;
	speak: none;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-top: 6px;
	text-align: center;
}

/* When radio button is checked, change label visual */
.c-product-selector__radio:checked + .c-product-selector__label {
	border-color: darken($highlight--darker,5);
	background: $highlight;
}

.js .c-product-selector__radio:checked + .c-product-selector__label:before {
	content: "\EA0B";
}

/* Disabled version */

.c-product-selector__radio:disabled + .c-product-selector__label {
	border-color: $highlight--darker !important;
	background: $highlight !important;
}
.c-product-selector__radio:checked:disabled + .c-product-selector__label {
	border-color: #666 !important;
}
.c-product-selector__radio:disabled + .c-product-selector__label:before {
	border-color: $highlight--darker !important;
	color: #666 !important;
}
.c-product-selector__radio:disabled + .c-product-selector__label .c-product-selector__name {
	color: #666 !important;
}