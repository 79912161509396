.c-shortlist-button {
	padding: 0;
	margin: 0;
	background: none;
	line-height: 1;
	border: 0;
	outline: 0;
}

.c-shortlist-button__text,
.c-icon-label {
	font-size: 0.9em;
}