.c-basket-credits {
    position: relative;
    @include clearfix();

    @include mq($until: lap) { 
        border-bottom: 1px solid $highlight--lighter;
        padding-bottom: 5px;
    }
}

.c-basket-credits__inner {
    float: right;
}

.js .c-basket-credits__inner {
    position: absolute;
    z-index: 1;
    right: 0px;
    top: -80px;

    @include mq($until: lap) { 
        position: static;
        float: right;
    }
}

.c-basket-credits__item {
    float: left;
    margin-left: 20px;
}

.c-credits-dropdown-label {
    padding: 0 0 5px 10px;

    .js & {
        cursor: pointer;
    }
}

.c-credits-dropdown__item {
    display: block;
    padding: 5px 0;
    margin-bottom: 5px;
    border-bottom: 1px solid $highlight;

    &--is-last {
        margin-bottom: 0;
        border-bottom: 0;
    }
}