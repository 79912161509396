.c-lister,
.c-mini-lister {
	margin: 0;
	padding: 0;
}
.c-lister__item {
	margin-bottom: 20px;
	position: relative;
	&:last-child {
		margin-bottom: 0;
	}
}
.c-lister__link {
	display: block;
	color: $bodyColour;

	&:hover {
		background: $highlight--lighter;
		color: $bodyColour;
	}
}

.c-lister__shortlist {
	position: absolute;
	top: 7px;
    right: 10px;

    @include mq($until: palm) {
    	top: auto;
    	bottom: 10px;
    }
}

.c-job-stub,
.c-recruiter-stub,
.c-cv-stub {
	border: 1px solid $highlight--darker;
	border-radius: 3px;
	padding: 10px;
}

@include mq($until: palm) {
	.c-job-stub {
		padding-bottom: 25px;
	}
}

.c-recruiter-stub__header-wrapper,
.c-article-stub__header-wrapper,
.c-cv-stub__header-wrapper {
	margin-bottom: 10px;
}

.c-job-stub__header-wrapper {
	margin-bottom: 2px;
}
.c-cv-stub__header-wrapper {
	padding-right: 50px;
}

.c-job-stub__header,
.c-recruiter-stub__header,
.c-article-stub__header,
.c-cv-stub__header {
	margin-bottom: 0;
	font-weight: bold
}
.c-job-stub__header,
.c-job-stub__new-flag {
	display: inline-block;
}
.c-job-stub__new-flag {
	color: #317F0F;
	text-transform: uppercase;
	padding-left: 5px;
	font-size: 13px;
	font-weight: normal;
	margin-bottom: 0;
	cursor: help;
}

.c-recruiter-stub__jobcount {
	display: inline-block;
	border-left: 1px solid $highlight--darker;
	margin-left: 7px;
	padding-left: 7px;
	font-size: 0.85em;
}

.c-job-stub__inner,
.c-recruiter-stub__inner,
.c-article-stub__inner,
.c-cv-stub__inner {
	position: relative;
}

.c-job-stub__details,
.c-recruiter-stub__details {
	margin-bottom: 10px;	
}
.c-job-stub__logo-wrapper,
.c-recruiter-stub__logo-wrapper {
	text-align: right;
}
.c-recruiter-stub__logo-wrapper {
	padding-top: 4px;
}


.c-job-stub__logo,
.c-recruiter-stub__logo {
	border: 1px solid $highlight--darker;
}

.c-job-stub__recruiter {
	margin-bottom: 10px;
}
.c-job-stub__description {
	margin-bottom: 0px;
}

.c-cv-stub__lock {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 130%;
}

.c-article-stub {
	border-bottom: 1px solid $highlight--darker;
	padding-bottom: 15px;

	.c-lister__item:last-child & {
		border-bottom: 0;
		padding-bottom: 0;
	}
}


.c-mini-lister-stub {
	border-bottom: 1px solid $highlight--darker;
	padding: 5px 0px;
	position: relative;

	.c-mini-lister__item:last-child & {
		border-bottom: 0;
	}
}
.c-mini-lister-stub__inner {
	padding-right: 20px;
}
.c-mini-lister-stub__header {
	font-size: 1em;
	margin-bottom: 0;
}
.c-mini-lister-stub__properties {
	margin-bottom: 0;
	color: $bodyColour;
}
.c-mini-lister-stub__spinner {
	position: absolute;
	top: calc(50% - 9px);
	right: 5px;
}