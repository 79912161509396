.c-static-bar {
	display: block;
	width: 100%;
	padding: 7px 0;
	background: $highlight;
	border-top: 1px solid $highlight--darker;
	text-align: center;
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 5;
}

.c-static-bar__inner {
	max-width: $bp-lap;
	margin: 0 auto;
}