@mixin clearfix() {
	& {
		zoom: 1 !important;
	}
	&:before,
	&:after {
		content: " " !important;
		display: table !important;
	}
	&:after {
		clear: both !important;
	}
}