.c-your-jobs-card {
	margin-bottom: $defaultVerticalMargin/2;
    border: 1px solid $highlight--darker;
    padding: 5px 10px;
    border-radius: 3px;
    @include clearfix();
}

.c-your-jobs-card__items,
.c-your-jobs-card ul {
	margin-bottom: 0;
}

.c-your-jobs-card__item {
	display: block;
	border-bottom: 1px dotted $highlight--darker;
    padding: 10px 0;

    &:last-child {
    	border-bottom: 0;
    }
}

.c-your-jobs-card__item--actions {
	font-size: 0.9em;
	padding-top: 25px;
}

@include mq($until:palm) {
	.c-your-jobs-card__item--actions .l-grid__item:first-child {
		margin-bottom: $defaultVerticalMargin/2;
	}
}