$brandColour                   : #2a6ebb;

$linkColour                    : $brandColour;
$linkColourHover               : darken($linkColour,20);

$buttonBgColour                : $brandColour;
$buttonBgColourHover           : darken($buttonBgColour,10);
$buttonMainActionBgColour      : #E75B0A;
$buttonMainActionBgColourHover : darken($buttonMainActionBgColour,10);

$brandHighlight                : lighten($linkColour,52);

a,
.h-link,
.b-link-colour {
	color: $linkColour;
}
a:hover,
.h-link:hover,
.b-link-colour:hover {
	color: $linkColourHover;
}

.b-button {
	background: $buttonBgColour;

	&:hover {
		background: $buttonBgColourHover;
	}
}

.b-button--main-action {
	background: $buttonMainActionBgColour;

	&:hover {
		background: $buttonMainActionBgColourHover;
	}
}

.b-highlight {
	background: $brandHighlight;
}

.c-primary-nav-o {}

.c-primary-nav__item--is-active .c-primary-nav__link,
.c-primary-nav-o__item--is-active .c-primary-nav-o__link {
	color: $linkColourHover;
}

.c-primary-nav-o__your-jobs-badge {}

/* Event date (little calendar thing) */
.c-event-date-calendar__month {
	background: $buttonMainActionBgColour;
}


/* Highlighted job on lister */
.c-lister__link:hover .b-highlight {
	background: darken($brandHighlight,3);
}

/* Product selector styling */
.c-product-selector__label:before,
.c-product-selector__name {
	color: $linkColour;
}
.c-product-selector__radio:checked + .c-product-selector__label {
	border-color: $linkColour;
	background: $brandHighlight;

	&:before {
		border-color: $linkColour;
		color: $linkColour;
	}
}
/* Unlocked CV */
.c-cv-stub--is-unlocked {
	background: $brandHighlight;
}

@include mq($until: lap) {
	.js .c-top-bar {
		background: $brandColour;
	}
}