/* Autocomplete - Coming from devbridgeautocomplete */
.autocomplete-suggestions {
	background: #fff;
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	border: 1px solid #ddd;
	overflow: auto;
}
.autocomplete-suggestion {
	cursor: default;
	text-align: left;
	overflow: auto;
	padding: 3px 5px;
}
.autocomplete-suggestions--dividers .autocomplete-suggestion {
	border-bottom: 1px solid #ddd;
	padding: 10px;
}
.autocomplete-suggestion:last-child {
	border: none;
}
.autocomplete-selected {
	background: #eee;
}
.autocomplete-suggestion strong {
	color: #333;
}
.autocomplete-no-suggestion {
	cursor: default;
	text-align: left;
	overflow: auto;
	padding: 3px 5px;
	opacity: 0.8;
}