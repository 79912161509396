.c-payment-cards-list {
	display: block;
	@include clearfix();
}

.c-payment-cards-list__item {
	display: block;
	float: left;
	margin-right: 5px;
	width: 40px;
}