.s-lister-jbe {
	margin-bottom: $defaultVerticalMargin;

	.js & {
		text-align: right;
	}
}

.js .s-lister-jbe--aligned-with-tabs {
	margin-bottom: -33px;
	@include mq($until: desk) {
		margin-bottom: $defaultVerticalMargin;
	}
}

.s-lister-jbe__button {
	width: auto;

	@include mq($until: palm) {
		width: 100%;
	}

	.no-js & {
		margin-bottom: $defaultVerticalMargin/2;
	}
}
.s-lister-jbe__form {
	min-width: 500px;
	max-width: 500px;

	@include mq($until: lap) {
		min-width: 0;
	}
}
.s-lister-jbe__field {
	margin-bottom: 0; 
	@include mq($until: lap) {
		margin-bottom: 15px;
	}
}