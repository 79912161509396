.c-attach-supporting-doc {
	padding: 15px;
	border: 1px solid $highlight;
	border-radius: 3px;
	position: relative;
	transition: opacity 0.5s;
}

.c-attach-supporting-doc--existing-doc {
	padding-top: 30px;
}

.c-attach-supporting-doc--remove-selected {
	opacity: 0.5;
}

.c-attach-supporting-doc__action-remove {
	position: absolute;
	top: 7px;
	right: 15px;
}

.js .c-remove-supporting-doc__checkbox {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.js .c-remove-supporting-doc__label {
	padding-right: 25px;
	font-size: 0.9em;
}
.js .c-remove-supporting-doc__label:after {
	content: '';
	display: block;
	width: 18px;
	height: 18px;
	border: 1px solid $highlight--darker;
	background: #fff;
	box-sizing: border-box;
	position: absolute;
	right: 0;
	top: 2px;

	font-family: 'mdgx-icons';
	color: inherit;
	speak: none;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-top: 3px;
	text-align: center;
	font-size: 11px;
}

.js .c-remove-supporting-doc__checkbox:checked + .c-remove-supporting-doc__label:after {
	content: "\EA34";
}
