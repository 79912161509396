.s-share-with-exposed-links {
	border-top: 1px solid $highlight--darker;
	padding-top: 15px;
	margin-bottom: 30px;
}
.s-share-with-exposed-links__label {
	margin-bottom: 10px;
}
.s-share-with-exposed-links .c-social-buttons {
	margin-bottom: 0;
}
.s-share-with-exposed-links .c-social-buttons__item {
	display: block;
	margin-right: 0;
}

.s-share-with-exposed-links .c-social-buttons__link {
	padding: 7px 0;
}

.s-share-with-exposed-links .c-social-buttons__item:last-child .c-social-buttons__link {
	padding-bottom: 0;
}

.s-share-with-exposed-links .c-social-buttons__link.i:before {
	font-size: 110%;
	width: 25px;
}
.s-share-with-exposed-links .c-social-buttons__name {
	font-size: 0.9em;
}