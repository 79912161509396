.c-job-description {
	margin-bottom: $defaultVerticalMargin;
}

.c-job-description__header {
	position: relative;
	padding-right: 70px;
}
.c-job-description__shortlist {
	position: absolute;
	top: 5px;
	right: 0;
}

/* Static bar appears at portable breakpoint */
@include mq($until: lap) {
	.c-job-description__header {
		padding-right: 0;
	}
	.c-job-description__shortlist {
		display: none;
	}
}