.c-facet-links__item {
	margin-bottom: 0px;

	&--is-active {
		margin-bottom: 6px;
	}
}

.c-facet-links__link {
	display: inline-block;
	border: 1px solid transparent;
	border-radius: 3px;
	width: 100%;
	padding: 3px 10px;
	@include clearfix();
}

.c-facet-links__item--is-active .c-facet-links__link {
	border-color: $highlight--darker;
}
.c-facet-links__count {
	color: $bodyColour;
	padding-left: 5px;
}

.c-facet-links__action {
	display: inline-block;
	float: right;
}

.c-facet-links__item--more,
.c-facet-links__item--less {
	display: none;

	.js & {
		display: block;
	}
}

.js .c-facet-links__more-links {
	display: none;
}