.c-sortby {
	margin-bottom: $defaultVerticalMargin/2;	
}

.c-sortby__label {
	margin-right: 10px;
}
.c-sortby__input {
	display: inline-block;
	width: auto;
}

.c-sortby__list {
	display: inline-block;
	vertical-align: bottom;
	margin-bottom: 0;

	& li {
		margin-bottom: 0;
	}
}