.c-brick {
	margin-bottom: $defaultVerticalMargin;
}
.c-brick,
.c-brick__inner {
	position: relative;
}

/* If .c-brick is contained in a grid with flexbox applied, apply display: flex */
.l-grid--flex.l-grid--stretch .c-brick {
	display: flex;
    display: -ms-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;

    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    & > * {
    	-webkit-flex: 1 1 auto;
	    -moz-flex: 1 1 auto;
	    -ms-flex: 1 1 auto;
	    flex: 1 1 auto;
    }
}

.c-brick__link {
	display: block;
	color: $bodyColour;
	@include clearfix();

	&:hover {
		color: $bodyColour;
		background: $highlight--lighter;
	}
}

.c-brick__header {
	margin-bottom: 14px;
}
.c-brick__header--shortlist-star {
	padding-right: 60px;
}

.c-brick__logo-wrapper {
	width: 100%;
	float: none;
	margin-bottom: 15px;
}

.c-brick__logo {
	border: 1px solid $highlight;
	display: block;
}

.c-brick__title {
	margin-bottom: 10px;
}

.c-brick__shortlist {
	position: absolute;
	top: 15px;
    right: 15px;
}



/*
 * Testimonial
 */

.c-speech-bubble {
	padding: 15px;
	border: 1px solid $highlight--darker;
	position: relative;
	background: #fff;
}

.c-speech-bubble:before,.c-speech-bubble:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-top: 1.5em solid #fff;
	border-right: 1.5em solid transparent;
}
.c-speech-bubble:before {
	left: 20px;
	bottom: -22px;
	z-index: 2;
}
.c-speech-bubble:after {
	left: 19px;
	bottom: -24px;
	border-top-color: $highlight--darker;
	z-index: 1;
}
.c-speech-bubble__author {
	margin-bottom: 0;
}

/*
 * CTAs
 */

.c-cta__icon {
 	float: left; 
}

.c-cta__icon i:after {
	font-size: 55px;
}
.c-cta__icon img {
	width: 55px;
}

.c-cta__content {
	padding-left: 75px;
}

.c-cta__header {
	margin-bottom: 5px;
}

/*
 * Featured Event
 */


.c-brick--featured-event .c-brick__content {
	position: relative;
	padding-left: 95px;
	min-height: 70px;
}

.c-brick--featured-event .c-brick__event-date-calendar {
	position: absolute;
	left: 0;
	top: 2px;
}

.c-event-date-calendar {
	position: relative;
	border-radius: 3px;
	background: #fff;
	text-align: center;
	width: 75px;
	border: 1px solid $highlight;
}

.c-event-date-calendar__month {
	background: $bodyColour;
	padding: 3px 0;
	font-size: 1em;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.c-event-date-calendar__day {
	padding: 4px 0;
	font-size: 1.5em;
}

.c-event-date-calendar__month:after,
.c-event-date-calendar__day:after {
	display: block;
	speak: none;
}

.c-event-date-calendar__month:after {
	content: attr(data-event-date-month);
	color: #fff;
	text-transform: uppercase;
}
.c-event-date-calendar__day:after {
	content: attr(data-event-date-day);
}

/* When screen gets too small for calendar to be next the text, put calendar on its own line and centered */
@include mq($until: 400px) {
	.c-brick--featured-event .c-brick__content {
		padding-left: 0;
	}
	.c-brick--featured-event .c-brick__event-date-calendar {
		position: static;
	}
	.c-event-date-calendar {
		margin: 0 auto $defaultVerticalMargin/2;
	}
}

/* Multi-items brick */

.c-brick__item, {
	clear: both;
	margin-bottom: $defaultVerticalMargin/2;
}

.c-brick--multi-items .c-brick__link:hover {
	background: none;
}

/*
 * We can have the logo and the content next to each other depending on the size of the parent grid item.
 * 
 * On Career Center, the brick " Featured jobs" can contain up to 4 jobs and the size of the brick itself is setup
 * in the CMS (it can be full width, two thirds, ...) which means that the logo can't always be next to the content
 * if there's not enough space in the container.
 * 
 * If the parent grid item is full width (one-whole -- the jobs would then be on top of each other), it means that
 * there is enough space for the logo to be next to the content.
 * Using another width (for example one-third) would put the jobs next to each other. In this case, we want to keep
 * the logo on top of the content as there won't be enough space for them to be next to each other.
 * 
 * We have to use media queries to get the right layout for each breakpoint (since we are using the breakpoint dependent
 * grid classes)
 *
 * Same thing for articles
*/

/* Desktop size => @media screen and (min-width: 1201px) */
@include mq($from:desk) {

	.l-grid__item.one-whole > .c-brick__item,
	.c-brick--featured-jobs .l-grid__item.one-half > .c-brick__item {

		& .c-brick__logo-wrapper {
			float: left;
		    width: 45%;
		    padding-right: 20px;
		}


		& .c-brick__logo-wrapper + .c-brick__content {
			float: left;
		    width: 55%;
		}
	}

}

/* Laptop size => @media screen and (min-width: 851px) and (max-width: 1200px) */
@include mq(lap, desk) {

	.l-grid__item.lap--one-whole > .c-brick__item,
	.c-brick--featured-jobs .l-grid__item.lap--one-half > .c-brick__item {

		& .c-brick__logo-wrapper {
			float: left;
		    width: 45%;
		    padding-right: 20px;
		}


		& .c-brick__logo-wrapper + .c-brick__content {
			float: left;
		    width: 55%;
		}
	}
}

/* Laptop size => @media screen and (min-width: 551px) and (max-width: 850px) */
@include mq(palm, lap) {
	.l-grid__item.portable--one-whole > .c-brick__item {

		& .c-brick__logo-wrapper {
			float: left;
		    width: 45%;
		    padding-right: 20px;
		}


		& .c-brick__logo-wrapper + .c-brick__content {
			float: left;
		    width: 55%;
		}
	}

}

/*
 * Products
 */

.c-brick--products .c-brick__item {
	padding: 35px 20px;
	border: 1px solid $highlight--darker;
	border-radius: 3px;
}
.c-brick--products .c-brick__content {
	max-width: 400px;
	margin: 0 auto;
}
.c-brick--products .c-brick__content li {
	margin-bottom: 10px;
}

.c-brick--products .c-brick__item--product-with-bundles {
	padding: 0;
	display: flex;
	flex-flow: column wrap;
}
.c-brick__item--product-with-bundles .c-brick__main-product {
	padding: 35px 20px 25px;
	flex: 1 1 auto;
}
.c-brick__item--product-with-bundles .c-brick__related-bundles {
	width: 100%;
	align-self: end;
}
.c-brick--products .c-related-bundles {
	margin-bottom: 0;
}
.c-brick--products .c-related-bundles__item {
	padding: 10px 15px 12px;
	border-top: 1px solid $highlight--darker;
	text-align: left;
}